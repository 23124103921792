import React from "react"

import "../styles/layout/title.scss"

export default (props) => {
  return (
    <section className="l-title">
      <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="200"
        data-sal-easing="easeOutCubic"
      >
        <h2 className="l-title_main">{props.top}</h2>
        <h3 className="l-title_sub">{props.topSub}</h3>
      </div>
    </section>
  )
}
