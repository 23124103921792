import React from "react"
import { Link } from "gatsby"

import "../styles/layout/cta.scss"
import "../styles/object/component/button.scss"

export default () => {
  return (
    <section
      className="l-cta"
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="200"
      data-sal-easing="easeOutCubic"
    >
      <h4 className="l-cta_title">CONTACT</h4>
      <p className="l-cta_message">
        弊社へのお問合せ・ご不明な点・ご相談がありましたらお気軽にご連絡ください。
      </p>
      <button className="c-button c-button--l c-button--gradation">
        <Link to={`/contact/`}>お問い合わせはこちら</Link>
      </button>
    </section>
  )
}
