import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import Cta from "../components/cta"
import {Link} from "gatsby"

import "../styles/object/component/table.scss"
import "../styles/object/component/typography.scss"
import "../styles/object/project/company.scss"
import define from "../config/define";

export default () => (
    <Layout title="Company">
        <Title top="COMPANY" topSub="会社案内"/>
        <main className="l-main">
            <section className="profile">
                <div className="c-heading">
                    <h4 className="c-heading_main">PROFILE</h4>
                    <h5 className="c-heading_sub">会社概要</h5>
                </div>
                <div className="container">
                    <table>
                        <tbody>
                        <tr>
                            <th>会社名</th>
                            <td>{define.corp.name} （{define.corp.name_en}）</td>
                        </tr>
                        <tr>
                            <th>法人番号</th>
                            <td>
                                <Link
                                    to={`https://www.houjin-bangou.nta.go.jp/henkorireki-johoto.html?selHouzinNo=${define.corp.no}`}
                                    className="c-linkExternal"
                                    target="_new"
                                >
                                    {define.corp.no} （国税庁登記情報）
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>設立</th>
                            <td>2016/05/10</td>
                        </tr>
                        <tr>
                            <th>資本金</th>
                            <td>{define.corp.capital}</td>
                        </tr>
                        <tr>
                            <th>事業内容</th>
                            <td>
                                <div>
                                    インターネット等の通信ネットワーク及び電子技術を利用した各種情報提供サービス
                                </div>
                                <div>
                                    コンピュータ、その周辺機器・関連機器及びそのソフトウェアの開発、設計、製造、販売並びに輸出入業務
                                </div>
                                <div>前（各）号に付帯関連する一切の事業</div>
                            </td>
                        </tr>
                        <tr>
                            <th>事業年度</th>
                            <td>毎年1月1日から12月31日まで</td>
                        </tr>
                        <tr>
                            <th>代表取締役</th>
                            <td>{define.corp.ceo}</td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>{define.corp.tel}</td>
                        </tr>
                        <tr>
                            <th>所在地</th>
                            <td>
                                {define.corp.postCode} {define.corp.addr}
                                <div className="g_map">
                                    <iframe
                                        src={define.corp.map}
                                        width="100%" height="100%" css="border:0;" allowFullScreen=""
                                        loading="lazy" title="東京都台東区小島2-20-11 LIGビル" />
                                    <Link
                                        to={define.corp.mapShort}
                                        className="c-linkExternal"
                                        target="_new"
                                    >
                                        GoogleMap
                                    </Link>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>主要取引先</th>
                            <td>
                                <div>
                                    <Link
                                        to="https://www.tworks.co.jp/"
                                        className="c-linkExternal"
                                        target="_new"
                                    >
                                        株式会社トレードワークス
                                    </Link>
                                </div>
                                <div>株式会社チャイムオブシガン</div>
                            </td>
                        </tr>
                        <tr>
                            <th>主要取引銀行</th>
                            <td>三菱UFJ銀行 / 楽天銀行 / 住信SBI銀行 / ゆうちょ銀行</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="history">
                <div className="c-heading">
                    <h4 className="c-heading_main">HISTORY</h4>
                    <h5 className="c-heading_sub">沿革</h5>
                </div>
                <div className="container">
                    <div className="historyListContainer">
                        {define.corp.histories.map(history =>
                          <div className="historyList">
                              <div className="historyContainer">
                                  <div className="historyContainer_date">{history.date}</div>
                                  <p className="historyContainer_description">{history.desc}</p>
                              </div>
                          </div>
                        )}
                    </div>
                </div>
            </section>
            <Cta/>
        </main>
    </Layout>
)
